<template>
  <v-img
    :src="imageSrc"
    :height="height"
    class="ma-0 pa-0"
    position="right"
    v-bind="$attrs"
  >
    <v-container
      fluid
      class="fill-height pa-3 pa-sm-12 ma-0"
      :style="gradientStyle"
    >
      <v-row no-gutters>
        <v-col cols="7" md="8" sm="12">
          <h1
            class="primary--text text-h4 text-sm-h3 text-md-h2 font-weight-bold text-uppercase"
          >
            {{ title }}
          </h1>
          <h2
            v-if="subtitle"
            class="text-h6 text-sm-h5 text-md-h4 font-weight-thin"
          >
            {{ subtitle }}
          </h2>
          <v-spacer></v-spacer>
          <div
            id="slot-container"
            class="d-flex justify-start flex-wrap mt-2 mt-sm-4 mt-md-10"
          >
            <slot />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-img>
</template>

<script>
export default {
  name: 'PageHeading',

  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: ''
    },
    image: {
      type: Number,
      default: 1
    },
    height: {
      type: String,
      default: '600px'
    }
  },

  computed: {
    imageSrc() {
      return [
        require('../assets/img/heading/image_1.jpg'),
        require('../assets/img/heading/image_2.jpg'),
        //require('../assets/img/heading/autre.jpg'),
      ][this.image - 1]
    },
    gradientStyle() {
      return `background: linear-gradient(90deg, white 0%, white 40%, rgba(${this.hexToRgba(
        this.$vuetify.theme.themes.light.primary
      )}, 0.5) 100%);`
    }
  },

  methods: {
    hexToRgba(hex) {
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return (
        parseInt(result[1], 16) +
        ',' +
        parseInt(result[2], 16) +
        ',' +
        parseInt(result[3], 16)
      )
    }
  }
}
</script>

<style scoped>
/deep/ #slot-container > * {
  margin-right: 5vw;
  margin-bottom: 2vh;
}
</style>
